export const ServerUrl = '';
export const ApiEndPointUrl = 'https://stagingapi.qbitt.io/api/v5/';
// export const ApiEndPointUrl = 'https://api.qbitt.io/api/v5/';

// export const ZoomApiEndPointUrl = 'https://api.qbitt.io/';  // 'https://stagingapi.qbitt.io/';

export const ZoomApiEndPointUrl = 'https://stagingapi.qbitt.io/';

export const RecaptchaSiteKey = '6Lf_GKYUAAAAAKuouNc6IWwY-0ANUl3WeB69Z2Nl';
export const Map_Api_Key = 'AIzaSyA0h6d75qPi7aa_Uk8ONEYDjG_3_H5-FxU';


// social login credentials
export const FacebookAppID = '293233741316303';  // for the production appID
// export const FacebookAppID = '378693120846241'; // according to app  for stagging 

export const Facebook_Secret_Id = 'd4cba901f39e42c63e9992e81a5ab1a0';
// export const Facebook_Secret_Id = 'c4182a26b4d023fb451db6542521aa11';
// old
// export const GoogleClientID = "386331632816-a6b1atnul7hsjn1ple8l62if7s35veug.apps.googleusercontent.com";
// export const Google_Secret_Key = 'ZsNgxv9Yd7MK4nR1HxO1B1nL';
// export const Google_Api_Key = 'AIzaSyBcCV1oT1Ykwk2rCjmY_dSQWL0w9u109dA';

// new
export const GoogleClientID = "684745177485-d66nvtjh8rsae12mlj6mlfm0ofeprfhh.apps.googleusercontent.com";
export const Google_Secret_Key = 'uHKWLPP0NAmb_XAlVn83G-74';
export const Google_Api_Key = 'AIzaSyAORvcTpWlT0eapdJhGGkuyhixvFavejI8';

// social login credentials

// microsoft credentials
// old  21-08-2024 export const Ms_Client_ID = '086166dd-f2bc-4f2d-acec-7a4925158d54'; // 'fdf759ad-5dcf-4679-a3ba-3df460b6181f';
export const Ms_Client_ID = 'cee0cdfe-2d07-4da7-9d17-f5c932a1f72d'; // 'fdf759ad-5dcf-4679-a3ba-3df460b6181f';
export const Ms_Tenant_ID = 'f8cdef31-a31e-4b4a-93e4-5f571e91255a';
export const MS_EndPoint =  'https://graph.microsoft.com/v1.0/me/';

// export const MS_Redirect_Uri = ((window.location.origin === 'http://34.193.201.83') ? 'http://34.193.201.83/qbitt-app' : window.location.origin) + '/appointment/book';
// export const MS_Redirect_Uri = ((window.location.origin === 'https://beta.qbitt.io') ? 'https://beta.qbitt.io' : window.location.origin) + '/appointment/book';
export const MS_Redirect_Uri = ('https://beta.qbitt.io/appointment/book');
// export const MS_Auth_Url =  'https://login.microsoftonline.com/common/';   // + Ms_Tenant_ID, // This is your tenant ID
export const MS_Auth_Url =  "https://login.microsoftonline.com/common/" ;   // + Ms_Tenant_ID, // This is your tenant ID
// microsoft credentials 
 
// zoom credentials
export const Zoom_CLIENT_ID = '27K_Zpk_ScuvYNH07CAIQg';
export const Zoom_CLIENT_SECRET = '37c0o3yazeuVVEYEDqlyiTJmICSzE8MK';
export const Zoom_CLIENT_ID_PROD = 'goiaggm6R_qQq1SiXFV0g';
export const Zoom_CLIENT_SECRET_PROD = 'e7zi0Sa591zgui4Q4SVcgG9W9Nz1R1T6';
// export const Zoom_REDIRECT_URL =  window.location.origin + '/zoom-callback';  // 'https://qbitt.io/';
export const Zoom_REDIRECT_URL =  ((window.location.origin === 'http://34.193.201.83') ?
    'http://34.193.201.83/qbitt-app' : window.location.origin)  + '/zoom-callback';

// app version
export const App_Version = '2.3.2b';

// app scopes
export const App_Scopes = {
    calendar: 'https://www.googleapis.com/auth/calendar',
    calendarReadOnly: 'https://www.googleapis.com/auth/calendar.readonly',
    calendarEvent: 'https://www.googleapis.com/auth/calendar.events',
    calendarEventReadOnly: 'https://www.googleapis.com/auth/calendar.events.readonly',
    contactReadOnly: 'https://www.googleapis.com/auth/contacts.readonly',
    microsoft: ['openid','profile','user.read','OnlineMeetings.ReadWrite','calendars.read','calendars.read.shared','group.read.all','mailboxsettings.read','calendars.readwrite','offline_access']
};

export const seattleGeocode = {
    latitude: 47.6062,
    longitude: -122.3321
};

export enum Pattern {
    Email = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$',
    Mobile = '[0-9]+',
    MobileMinLength = 10,
    MobileMaxLength = 12,
    Password = '^(?=.*?[A-Z])(?=.*?[0-9]).{6,}$'
}

// userType values
// 1  = Personal
// 2  = Business
// 3  = Employee
export enum userType {
    Personal = 1,
    Business = 2,
    Employee = 3
}

// appointmentType values
// 1  = Personal
// 2  = Business
export enum appointmentType {
    Personal = 1,
    Business = 2,
}

export enum ORIGIN {
    QBITT = 1,
    GOOGLE = 2,
    OFFICE365 = 3,
    ANDROID = 4,
    APPLE = 5,
}

// appointmentUserType values
// 1  = MySelf
// 2  = Business
// 3  = Friends
// 4  = Employee
export enum appointmentUserType {
    MySelf              = 1,
    Business            = 2,
    Friends             = 3,
    Employee            = 4,
    Followers           = 5,
    Previous_Customer   = 6
}

export enum timeSlotUserType {
    Member      = 1,
    Business    = 2,
    Employee    = 3
}

export enum responseType {
    FOR_AVAILABILITY    = 1,
    FOR_BLOCK           = 2
}

// privacy values
// 1  = Public
// 2  = Private
// 3  = Connections
export enum privacy {
    Public      = 1,
    Private     = 2,
    Connections = 3
}


export enum favouriteType {
    Public      = 1,
    Private     = 2,
    Friends     = 3
}

// connectionStatus values
// 1  = No connection
// 2  = Request sent
// 3  = Connected
// 4  = Request received
export enum connectionStatus {
    NoConnection        = 0,
    RequestSent         = 1,
    Connected           = 2,
    RequestReceived     = 3
}


export enum appointmentOrigin {
    Qbitt       = 1,
    Google      = 2,
    Office365   = 3,
    Android     = 4,
    Apple       = 5,
}
export enum syncType {
    isPersonalGoogleSync = 1,
    isPersonalOfficeSync = 2,
    isBusinessGoogleSync = 3,
    isBusinessOfficeSync = 4,
}

export enum meetingType {
    DEFAULT     = 0,
    GOOGLE      = 1,
    OFFICE      = 2,
    ZOOM        = 3,
    OTHER       = 4
}

export enum rescheduleCancellationPolicy {
    'ANYTIME' = 0,
    'THIRTY MINUTES' = 1,
    'ONE HOUR' = 2,
    'TWO HOUR' = 3,
    'THREE HOUR' = 4,
    'FOUR HOUR' = 5,
    'SIX HOUR' = 6,
    'TWELVE HOUR' = 7,
    'ONE DAY' = 8,
    'TWO DAYS' = 9,
    'THREE DAYS' = 10,
    'FOUR DAYS' = 11,
    'SEVEN DAYS' = 12,
    'TEN DAYS' = 13,
    'NEVER' = -1,
}

export enum bookingSettings {
    'NO_LIMIT' = 0,
    'FIVE MINUTES' = 5,
    'TEN MINUTES' = 10,
    'FIFTEEN MINUTES' = 15,
    'THIRTY MINUTES' = 30,
    'ONE HOUR' = 60,
    'TWO HOUR' = 60 * 2,
    'FIVE HOUR' = 60 * 5,
    'TWELVE HOUR' = 60 * 12,
    'ONE DAY' = 60 * 24,
    'FIVE DAYS' = 60 * 24 * 5,
    'TEN DAYS' = 60 * 24 * 10,
    'THIRTY DAYS' = 60 * 24 * 30,
}


export enum radius {
    Unlimited       = 0,
    Mile1           = 1,
    Mile5           = 5,
    Mile10          = 10,
    Mile15          = 15,
    Mile20          = 20,
    Mile25          = 25,
    Mile50          = 50,
    Mile100         = 100,
}

// businessSorting values
// 1  = Top Rated Business (Recommended)
// 2  = Near By Business
// 3  = A to Z Business
// 4  = Z to A Business
// 5  = Favourite on Top
export enum businessSorting {
    TopRatedBusiness        = 1,
    NearByBusiness          = 2,
    AtoZBusiness            = 3,
    ZtoABusiness            = 4,
    FavouriteOnTop          = 5
}

// firebaseTracking values
// 0  = No Tracking (Both get request option)
// 1  = Tracking Request (One get cancel & other get accept or reject options)
// 2  = Tracking Accept (One get cancel & other get stop option)
export enum firebaseTracking {
    NoTracking          = 0,
    RequestTracking     = 1,
    AcceptTracking      = 2
}

// connectionActionType values
// 1  = Accept
// 2  = Reject
// 3  = Remove
export enum connectionActionType {
    Accept      = 1,
    Reject      = 2,
    Remove      = 3,
}

// connectionActionType values
// 0  = Booked
// 1  = Available
// 2  = Blocked
export enum appointmentAvailability {
    Booked          = 0,
    Available       = 1,
    Blocked         = 2,
    Unavailable     = 3
}

// appointmentStatus values
// 1  = Pending
// 2  = Accepted
export enum appointmentStatus {
    Pending      = 1,
    Accepted      = 2,
}


export enum appointmentAction {
    Accept      = 1,
    Reject      = 2,
}

// appointmentRecurringType values
// 1  = daily
// 2  = weekly
// 3  = monthly
// 4  = yearly
export enum appointmentRecurringType {
    never   =  0,
    everyDay = 1,
    everyWeek = 2,
    everyMonth = 3,
    everyYear = 4,
    everyCustom = 5
}

// recurringAppointmentUpdate values
// 1  = Update/Delete Appointment for selected date
// 2  = Update/Delete Appointment for future dates
// 3  = Update/Delete Appointment for all recurring date
export enum recurringAppointmentUpdate {
    updateSelectedDateAppointment           = 1,
    updateFutureDateAppointment             = 2,
    updateAllRecurringDateAppointment       = 3,
}


export enum notification {
    On  = 1,
    Off = 0
}

export enum allowOverlapping {
    On  = 1,
    Off = 0
}


export enum NotifyType {
    APPOINTMENT_SELF = 5,
    APPOINTMENT_CREATED = 6,
    APPOINTMENT_MEMBER_TO_BUSINESS = 7,
    APPOINTMENT_MEMBER_TO_BUSINESS_EMPLOYEE = 8,
    APPOINTMENT_SCHEDULE_REMINDER = 9,
    APPOINTMENT_RESCHEDULE_EDITED = 10,
    APPOINTMENT_DELETE = 11,
    APPOINTMENT_SELF_BUSINESS = 30,
    APPOINTMENT_EMPLOYEE_BUSINESS = 31,
    APPOINTMENT_COMPLETED = 33,
    APPOINTMENT_FEEDBACK = 34,
    APPOINTMENT_REQUEST_ACCEPT_DECLINE_USER = 35,
    APPOINTMENT_REQUEST_FROM_BUSINESS = 36,
    FRIEND_REQUEST = 12,
    FRIEND_REQUEST_ACCEPTED = 13,
    NEW_POST = 14,
    POST_COMMENT = 15,
    POST_LIKE = 16,
    EMPLOYEMENT_REQUEST = 17,
    MEMBER_ACCEPT_EMPLOYEMENT = 18,
    MEMBER_REMOVED = 25,
    MEMBER_BLOCKED = 26,
    MEMBER_LEAVE_BUSINESS = 27,
    BUSINESS_REMOVE_EMPLOYEE = 28,
    CHAT_NOTIFICATION = 29,
}


export enum loginBy {
    EMAIL = 1,
    FACEBOOK = 2,
    GOOGLE = 3
}

export enum referralStatus {
    Pending = 0,
    Accepted = 3,
}

export enum defaultHome {
    CALENDER = 0,
    BUSINESS_DIRECTORY = 1,
    FEED = 2,
    NOTIFICATION = 3,
    PROFILE = 4
}


export const Enums = { 
 Countries :[
    { code: "AFG", name: "Afghanistan", number: "004" },
    { code: "ALB", name: "Albania", number: "008" },
    { code: "DZA", name: "Algeria", number: "012" },
    { code: "ASM", name: "American Samoa", number: "016" },
    { code: "AND", name: "Andorra", number: "020" },
    { code: "AGO", name: "Angola", number: "024" },
    { code: "AIA", name: "Anguilla", number: "660" },
    { code: "ATA", name: "Antarctica", number: "010" },
    { code: "ATG", name: "Antigua and Barbuda", number: "028" },
    { code: "ARG", name: "Argentina", number: "032" },
    { code: "ARM", name: "Armenia", number: "051" },
    { code: "ABW", name: "Aruba", number: "533" },
    { code: "AUS", name: "Australia", number: "036" },
    { code: "AUT", name: "Austria", number: "040" },
    { code: "AZE", name: "Azerbaijan", number: "031" },
    { code: "BHS", name: "Bahamas ", number: "044" },
    { code: "BHR", name: "Bahrain", number: "048" },
    { code: "BGD", name: "Bangladesh", number: "050" },
    { code: "BRB", name: "Barbados", number: "052" },
    { code: "BLR", name: "Belarus", number: "112" },
    { code: "BEL", name: "Belgium", number: "056" },
    { code: "BLZ", name: "Belize", number: "084" },
    { code: "BEN", name: "Benin", number: "204" },
    { code: "BMU", name: "Bermuda", number: "060" },
    { code: "BTN", name: "Bhutan", number: "064" },
    { code: "BOL", name: "Bolivia (Plurinational State of)", number: "068" },
    { code: "BES", name: "Bonaire, Sint Eustatius and Saba", number: "535" },
    { code: "BIH", name: "Bosnia and Herzegovina", number: "070" },
    { code: "BWA", name: "Botswana", number: "072" },
    { code: "BVT", name: "Bouvet Island", number: "074" },
    { code: "BRA", name: "Brazil", number: "076" },
    { code: "IOT", name: "British Indian Ocean Territory ", number: "086" },
    { code: "BRN", name: "Brunei Darussalam", number: "096" },
    { code: "BGR", name: "Bulgaria", number: "100" },
    { code: "BFA", name: "Burkina Faso", number: "854" },
    { code: "BDI", name: "Burundi", number: "108" },
    { code: "CPV", name: "Cabo Verde", number: "132" },
    { code: "KHM", name: "Cambodia", number: "116" },
    { code: "CMR", name: "Cameroon", number: "120" },
    { code: "CAN", name: "Canada", number: "124" },
    { code: "CYM", name: "Cayman Islands ", number: "136" },
    { code: "CAF", name: "Central African Republic ", number: "140" },
    { code: "TCD", name: "Chad", number: "148" },
    { code: "CHL", name: "Chile", number: "152" },
    { code: "CHN", name: "China", number: "156" },
    { code: "CXR", name: "Christmas Island", number: "162" },
    { code: "CCK", name: "Cocos (Keeling) Islands ", number: "166" },
    { code: "COL", name: "Colombia", number: "170" },
    { code: "COM", name: "Comoros ", number: "174" },
    { code: "COD", name: "Congo (the Democratic Republic of the)", number: "180" },
    { code: "COG", name: "Congo ", number: "178" },
    { code: "COK", name: "Cook Islands ", number: "184" },
    { code: "CRI", name: "Costa Rica", number: "188" },
    { code: "HRV", name: "Croatia", number: "191" },
    { code: "CUB", name: "Cuba", number: "192" },
    { code: "CUW", name: "Curaçao", number: "531" },
    { code: "CYP", name: "Cyprus", number: "196" },
    { code: "CZE", name: "Czechia", number: "203" },
    { code: "CIV", name: "Côte d'Ivoire", number: "384" },
    { code: "DNK", name: "Denmark", number: "208" },
    { code: "DJI", name: "Djibouti", number: "262" },
    { code: "DMA", name: "Dominica", number: "212" },
    { code: "DOM", name: "Dominican Republic ", number: "214" },
    { code: "ECU", name: "Ecuador", number: "218" },
    { code: "EGY", name: "Egypt", number: "818" },
    { code: "SLV", name: "El Salvador", number: "222" },
    { code: "GNQ", name: "Equatorial Guinea", number: "226" },
    { code: "ERI", name: "Eritrea", number: "232" },
    { code: "EST", name: "Estonia", number: "233" },
    { code: "SWZ", name: "Eswatini", number: "748" },
    { code: "ETH", name: "Ethiopia", number: "231" },
    { code: "FLK", name: "Falkland Islands  [Malvinas]", number: "238" },
    { code: "FRO", name: "Faroe Islands ", number: "234" },
    { code: "FJI", name: "Fiji", number: "242" },
    { code: "FIN", name: "Finland", number: "246" },
    { code: "FRA", name: "France", number: "250" },
    { code: "GUF", name: "French Guiana", number: "254" },
    { code: "PYF", name: "French Polynesia", number: "258" },
    { code: "ATF", name: "French Southern Territories ", number: "260" },
    { code: "GAB", name: "Gabon", number: "266" },
    { code: "GMB", name: "Gambia ", number: "270" },
    { code: "GEO", name: "Georgia", number: "268" },
    { code: "DEU", name: "Germany", number: "276" },
    { code: "GHA", name: "Ghana", number: "288" },
    { code: "GIB", name: "Gibraltar", number: "292" },
    { code: "GRC", name: "Greece", number: "300" },
    { code: "GRL", name: "Greenland", number: "304" },
    { code: "GRD", name: "Grenada", number: "308" },
    { code: "GLP", name: "Guadeloupe", number: "312" },
    { code: "GUM", name: "Guam", number: "316" },
    { code: "GTM", name: "Guatemala", number: "320" },
    { code: "GGY", name: "Guernsey", number: "831" },
    { code: "GIN", name: "Guinea", number: "324" },
    { code: "GNB", name: "Guinea-Bissau", number: "624" },
    { code: "GUY", name: "Guyana", number: "328" },
    { code: "HTI", name: "Haiti", number: "332" },
    { code: "HMD", name: "Heard Island and McDonald Islands", number: "334" },
    { code: "VAT", name: "Holy See ", number: "336" },
    { code: "HND", name: "Honduras", number: "340" },
    { code: "HKG", name: "Hong Kong", number: "344" },
    { code: "HUN", name: "Hungary", number: "348" },
    { code: "ISL", name: "Iceland", number: "352" },
    { code: "IND", name: "India", number: "356" },
    { code: "IDN", name: "Indonesia", number: "360" },
    { code: "IRN", name: "Iran (Islamic Republic of)", number: "364" },
    { code: "IRQ", name: "Iraq", number: "368" },
    { code: "IRL", name: "Ireland", number: "372" },
    { code: "IMN", name: "Isle of Man", number: "833" },
    { code: "ISR", name: "Israel", number: "376" },
    { code: "ITA", name: "Italy", number: "380" },
    { code: "JAM", name: "Jamaica", number: "388" },
    { code: "JPN", name: "Japan", number: "392" },
    { code: "JEY", name: "Jersey", number: "832" },
    { code: "JOR", name: "Jordan", number: "400" },
    { code: "KAZ", name: "Kazakhstan", number: "398" },
    { code: "KEN", name: "Kenya", number: "404" },
    { code: "KIR", name: "Kiribati", number: "296" },
    { code: "PRK", name: "Korea (the Democratic People's Republic of)", number: "408" },
    { code: "KOR", name: "Korea (the Republic of)", number: "410" },
    { code: "KWT", name: "Kuwait", number: "414" },
    { code: "KGZ", name: "Kyrgyzstan", number: "417" },
    { code: "LAO", name: "Lao People's Democratic Republic ", number: "418" },
    { code: "LVA", name: "Latvia", number: "428" },
    { code: "LBN", name: "Lebanon", number: "422" },
    { code: "LSO", name: "Lesotho", number: "426" },
    { code: "LBR", name: "Liberia", number: "430" },
    { code: "LBY", name: "Libya", number: "434" },
    { code: "LIE", name: "Liechtenstein", number: "438" },
    { code: "LTU", name: "Lithuania", number: "440" },
    { code: "LUX", name: "Luxembourg", number: "442" },
    { code: "MAC", name: "Macao", number: "446" },
    { code: "MDG", name: "Madagascar", number: "450" },
    { code: "MWI", name: "Malawi", number: "454" },
    { code: "MYS", name: "Malaysia", number: "458" },
    { code: "MDV", name: "Maldives", number: "462" },
    { code: "MLI", name: "Mali", number: "466" },
    { code: "MLT", name: "Malta", number: "470" },
    { code: "MHL", name: "Marshall Islands ", number: "584" },
    { code: "MTQ", name: "Martinique", number: "474" },
    { code: "MRT", name: "Mauritania", number: "478" },
    { code: "MUS", name: "Mauritius", number: "480" },
    { code: "MYT", name: "Mayotte", number: "175" },
    { code: "MEX", name: "Mexico", number: "484" },
    { code: "FSM", name: "Micronesia (Federated States of)", number: "583" },
    { code: "MDA", name: "Moldova (the Republic of)", number: "498" },
    { code: "MCO", name: "Monaco", number: "492" },
    { code: "MNG", name: "Mongolia", number: "496" },
    { code: "MNE", name: "Montenegro", number: "499" },
    { code: "MSR", name: "Montserrat", number: "500" },
    { code: "MAR", name: "Morocco", number: "504" },
    { code: "MOZ", name: "Mozambique", number: "508" },
    { code: "MMR", name: "Myanmar", number: "104" },
    { code: "NAM", name: "Namibia", number: "516" },
    { code: "NRU", name: "Nauru", number: "520" },
    { code: "NPL", name: "Nepal", number: "524" },
    { code: "NLD", name: "Netherlands ", number: "528" },
    { code: "NCL", name: "New Caledonia", number: "540" },
    { code: "NZL", name: "New Zealand", number: "554" },
    { code: "NIC", name: "Nicaragua", number: "558" },
    { code: "NER", name: "Niger ", number: "562" },
    { code: "NGA", name: "Nigeria", number: "566" },
    { code: "NIU", name: "Niue", number: "570" },
    { code: "NFK", name: "Norfolk Island", number: "574" },
    { code: "MNP", name: "Northern Mariana Islands ", number: "580" },
    { code: "NOR", name: "Norway", number: "578" },
    { code: "OMN", name: "Oman", number: "512" },
    { code: "PAK", name: "Pakistan", number: "586" },
    { code: "PLW", name: "Palau", number: "585" },
    { code: "PSE", name: "Palestine, State of", number: "275" },
    { code: "PAN", name: "Panama", number: "591" },
    { code: "PNG", name: "Papua New Guinea", number: "598" },
    { code: "PRY", name: "Paraguay", number: "600" },
    { code: "PER", name: "Peru", number: "604" },
    { code: "PHL", name: "Philippines ", number: "608" },
    { code: "PCN", name: "Pitcairn", number: "612" },
    { code: "POL", name: "Poland", number: "616" },
    { code: "PRT", name: "Portugal", number: "620" },
    { code: "PRI", name: "Puerto Rico", number: "630" },
    { code: "QAT", name: "Qatar", number: "634" },
    { code: "MKD", name: "Republic of North Macedonia", number: "807" },
    { code: "ROU", name: "Romania", number: "642" },
    { code: "RUS", name: "Russian Federation ", number: "643" },
    { code: "RWA", name: "Rwanda", number: "646" },
    { code: "REU", name: "Réunion", number: "638" },
    { code: "BLM", name: "Saint Barthélemy", number: "652" },
    { code: "SHN", name: "Saint Helena, Ascension and Tristan da Cunha", number: "654" },
    { code: "KNA", name: "Saint Kitts and Nevis", number: "659" },
    { code: "LCA", name: "Saint Lucia", number: "662" },
    { code: "MAF", name: "Saint Martin (French part)", number: "663" },
    { code: "SPM", name: "Saint Pierre and Miquelon", number: "666" },
    { code: "VCT", name: "Saint Vincent and the Grenadines", number: "670" },
    { code: "WSM", name: "Samoa", number: "882" },
    { code: "SMR", name: "San Marino", number: "674" },
    { code: "STP", name: "Sao Tome and Principe", number: "678" },
    { code: "SAU", name: "Saudi Arabia", number: "682" },
    { code: "SEN", name: "Senegal", number: "686" },
    { code: "SRB", name: "Serbia", number: "688" },
    { code: "SYC", name: "Seychelles", number: "690" },
    { code: "SLE", name: "Sierra Leone", number: "694" },
    { code: "SGP", name: "Singapore", number: "702" },
    { code: "SXM", name: "Sint Maarten (Dutch part)", number: "534" },
    { code: "SVK", name: "Slovakia", number: "703" },
    { code: "SVN", name: "Slovenia", number: "705" },
    { code: "SLB", name: "Solomon Islands", number: "090" },
    { code: "SOM", name: "Somalia", number: "706" },
    { code: "ZAF", name: "South Africa", number: "710" },
    { code: "SGS", name: "South Georgia and the South Sandwich Islands", number: "239" },
    { code: "SSD", name: "South Sudan", number: "728" },
    { code: "ESP", name: "Spain", number: "724" },
    { code: "LKA", name: "Sri Lanka", number: "144" },
    { code: "SDN", name: "Sudan ", number: "729" },
    { code: "SUR", name: "Suriname", number: "740" },
    { code: "SJM", name: "Svalbard and Jan Mayen", number: "744" },
    { code: "SWE", name: "Sweden", number: "752" },
    { code: "CHE", name: "Switzerland", number: "756" },
    { code: "SYR", name: "Syrian Arab Republic", number: "760" },
    { code: "TWN", name: "Taiwan", number: "158" },
    { code: "TJK", name: "Tajikistan", number: "762" },
    { code: "TZA", name: "Tanzania, United Republic of", number: "834" },
    { code: "THA", name: "Thailand", number: "764" },
    { code: "TLS", name: "Timor-Leste", number: "626" },
    { code: "TGO", name: "Togo", number: "768" },
    { code: "TKL", name: "Tokelau", number: "772" },
    { code: "TON", name: "Tonga", number: "776" },
    { code: "TTO", name: "Trinidad and Tobago", number: "780" },
    { code: "TUN", name: "Tunisia", number: "788" },
    { code: "TUR", name: "Turkey", number: "792" },
    { code: "TKM", name: "Turkmenistan", number: "795" },
    { code: "TCA", name: "Turks and Caicos Islands ", number: "796" },
    { code: "TUV", name: "Tuvalu", number: "798" },
    { code: "UGA", name: "Uganda", number: "800" },
    { code: "UKR", name: "Ukraine", number: "804" },
    { code: "ARE", name: "United Arab Emirates ", number: "784" },
    { code: "GBR", name: "United Kingdom of Great Britain and Northern Ireland ", number: "826" },
    { code: "UMI", name: "United States Minor Outlying Islands ", number: "581" },
    { code: "USA", name: "United States of America", number: "840" },
    { code: "URY", name: "Uruguay", number: "858" },
    { code: "UZB", name: "Uzbekistan", number: "860" },
    { code: "VUT", name: "Vanuatu", number: "548" },
    { code: "VEN", name: "Venezuela (Bolivarian Republic of)", number: "862" },
    { code: "VNM", name: "Viet Nam", number: "704" },
    { code: "VGB", name: "Virgin Islands (British)", number: "092" },
    { code: "WLF", name: "Wallis and Futuna", number: "876" },
    { code: "ESH", name: "Western Sahara", number: "732" },
    { code: "YEM", name: "Yemen", number: "887" },
    { code: "ZMB", name: "Zambia", number: "894" },
    { code: "ZWE", name: "Zimbabwe", number: "716" },
    { code: "ALA", name: "Åland Islands", number: "248" }
]
}
